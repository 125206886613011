import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  // home
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/home/index"),
  },
  {
    path: "/qualification",
    name: "qualification",
    component: () =>
      import(
        /* webpackChunkName: "qualification" */ "@/views/qualification/index"
      ),
  },
  // 简介
  {
    path: "/introduction",
    name: "introduction",
    component: () =>
      import(
        /* webpackChunkName: "introduction" */ "@/views/introduction/index"
      ),
  },
  // 案例
  {
    path: "/case",
    name: "case",
    component: () =>
      import(/* webpackChunkName: "case" */ "@/views/case/index"),
  },
  // 联系我们
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "@/views/contact/index"),
  },
  // 客户管理
  {
    path: "/CustomerManager",
    name: "CustomerManager",
    component: () =>
      import(/* webpackChunkName: "contact" */ "@/views/CustomerManager/index"),
    meta: {
      requiresParams: true, // 需要检查 URL 参数
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

function generateComplexKey() {
  const today = new Date();

  // 1. 获取当前日期的 YYYYMMDD
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");

  // 2. 将年月日拼接为一个字符串
  const dateStr = `${year}${month}${day}`;

  // 3. 对日期字符串进行固定规则的变换
  // 举例: 将字符串倒序 + 每个数字加固定位移（比如加3）
  const transformedDateStr = dateStr
    .split("")
    .reverse() // 倒序
    .map((char) => String((parseInt(char) + 3) % 10)) // 每个数字加3，超出9则取模10
    .join("");

  // 4. 固定加入一些字符，增加复杂性（比如中间插入一串字母）
  const complexKey =
    transformedDateStr.slice(0, 4) + "ABCD" + transformedDateStr.slice(4);

  return complexKey;
}

// 自定义函数解析 hash 模式下的查询参数
function getQueryParamsFromHash() {
  const hash = window.location.hash;
  const queryStart = hash.indexOf("?");
  const queryString = queryStart !== -1 ? hash.substring(queryStart + 1) : "";
  return new URLSearchParams(queryString); // 使用 URLSearchParams 解析查询字符串
}

// 路由守卫，校验 CustomerManager 页面的 URL 参数
router.beforeEach((to, from, next) => {
  if (to.meta.requiresParams) {
    const urlParams = getQueryParamsFromHash(); // 获取 hash 部分的查询参数
    const key = urlParams.get("key");
    const expectedKey = generateComplexKey(); // 获取预期的复杂 key

    // 校验 key 是否等于明天的日期
    if (key !== expectedKey) {
      // 如果参数不合法，阻止导航并后退
      next(false); // 阻止页面跳转
      window.history.back(); // 页面后退
    } else {
      next(); // 参数合法，允许页面跳转
    }
  } else {
    next(); // 不需要校验参数的页面，直接允许导航
  }
});

export default router;
